import React, { Suspense, lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const Ssl = lazy(() => import('sections/hero/Ssl.js'));
const TiposSSL = lazy(() => import('sections/services/TiposSSL.js'));
const LlamadoSSL = lazy(() => import('sections/hero/LlamadoSSL.js'));
const Hola = lazy(() => import('sections/hero/Hola.js'));
const CantidadSSL = lazy(() => import('sections/services/CantidadSSL.js'));
const ValidacionSSL = lazy(() => import('sections/hero/ValidacionSSL.js'));
const FlexSSL = lazy(() => import('sections/services/FlexSSL.js'));
const BotonSanGeo = lazy(() => import('sections/services/BotonSanGeo.js'));
const OnacSSL = lazy(() => import('sections/hero/OnacSSL.js'));
const SelloSitelock = lazy(() => import('sections/about/SelloSitelock.js'));
const SeguridadInformatica = lazy(() => import('sections/about/SeguridadInformatica.js'));
const CaSSL = lazy(() => import('sections/services/CaSSL.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const FaqGeoTrust = lazy(() => import('../../sections/services/FaqGeotrust'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {
    
    
    return (
      <div>
        <Helmet>
          <title>Certificados SSL en Colombia: Seguridad TLS para tu sitio web</title>
          <meta name="description" content="Asegura tu sitio web con nuestros certificados SSL en Colombia. TLS confiables para proteger la información y mejorar tu posicionamiento en buscadores con HTTPS." />
        </Helmet>
        <Layout>
          <Suspense fallback={<div>Cargando...</div>}>
            <Ssl />
            <TiposSSL />
            <ValidacionSSL />
            <CantidadSSL />
            <CaSSL />
            <FlexSSL />
            <OnacSSL />
            <LlamadoSSL />
            <Hola />
            <BotonSanGeo />
            <SelloSitelock />
            <SeguridadInformatica />
            <FaqGeoTrust />
            <BotonSanGeo />
            <Llamenos />
            <ContactCreative />
            <Footer />
          </Suspense>
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`